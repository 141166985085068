var render = function () {
  var _vm$numWords, _vm$remarks, _vm$numWords2, _vm$remarks2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-add-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-add"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "logo-wrapper"
  }, [_vm.companyLogo ? [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center invoice-spacing"
  }, [_c('div', [_c('h1', {
    staticClass: "mb-30"
  }, [_vm._v("Invoice")]), _c('b', [_vm._v(_vm._s(_vm.sellerName))]), _vm.sellerWebsite ? _c('div', [_vm._v(_vm._s(_vm.sellerWebsite))]) : _vm._e(), _vm.sellerEmail ? _c('div', [_vm._v(_vm._s(_vm.sellerEmail))]) : _vm._e(), _vm.sellerContact ? _c('div', [_vm._v(_vm._s(_vm.sellerContact))]) : _vm._e()]), _c('div', {
    staticClass: "invoice-number-date mt-md-0 mt-2"
  }, [_c('h6', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice Number")]), _c('div', [_vm._v(_vm._s(_vm.invoiceId))]), _c('h6', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice Date")]), _c('div', [_vm._v(_vm._s(_vm.invoiceDate))]), _c('h6', {
    staticClass: "title"
  }, [_vm._v(" Due Date ")]), _c('div', [_vm._v(_vm._s(_vm.paymentDate))])]), _c('div', [_c('h6', {
    staticClass: "mb-30"
  }, [_vm._v(_vm._s(_vm.companyName))]), _c('br'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Phone: " + _vm._s(this.officeMobile))]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Email" + _vm._s(this.officeEmail))])])])]), _c('b-card-body', {
    staticClass: "invoice-padding form-item-section"
  }, [_c('div', {
    ref: "form",
    staticClass: "repeater-form"
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', {
    staticClass: "table-light"
  }, [_c('tr', [_c('th', [_vm._v("Item")]), _c('th', [_vm._v("Project")]), _c('th', [_vm._v("Type")]), _c('th', {
    staticClass: "text-end"
  }, [_vm._v("Amount")])])]), _c('tbody', [_vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project, _item$project$data, _item$currency, _item$currency$data;
    return _c('tr', {
      key: index
    }, [_c('td', [item.backlink_url ? _c('div', [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Backlink: ")]), _c('a', {
      attrs: {
        "href": _vm.getBacklinkUrl(item.backlink_url),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatWebsite(_vm.getBacklinkUrl(item.backlink_url))) + " ")])]) : _vm._e(), item.targeted_url ? _c('div', [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("URL: ")]), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(item.targeted_url) + " ")])]) : _vm._e()]), _c('td', [_vm._v(_vm._s(((_item$project = item.project) === null || _item$project === void 0 ? void 0 : (_item$project$data = _item$project.data) === null || _item$project$data === void 0 ? void 0 : _item$project$data.title) || 'N/A'))]), _c('td', [_vm._v(_vm._s(item.backlinks_type_text || 'N/A'))]), _c('td', {
      staticClass: "text-end"
    }, [_vm._v(" " + _vm._s(((_item$currency = item.currency) === null || _item$currency === void 0 ? void 0 : (_item$currency$data = _item$currency.data) === null || _item$currency$data === void 0 ? void 0 : _item$currency$data.currency) || '') + " " + _vm._s(item.cost_price || '0.00') + " ")])]);
  }), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Subtotal")])]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.subTotalAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Discount"), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.discountAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Fee"), _vm.feeType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.fee_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticClass: "text-end"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.feeAmount))])])]), _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Grand Total")])]), _c('td', {
    staticClass: "text-end text-danger"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.grandTotalAmount))])])])], 2)]), _c('div', {
    staticClass: "mt-5"
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("In Words: " + _vm._s(_vm.getCurrency()) + " " + _vm._s((_vm$numWords = _vm.numWords(_vm.grandTotalAmount)) === null || _vm$numWords === void 0 ? void 0 : _vm$numWords.toUpperCase()))]), _c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Note/Terms")]), _c('b', [_vm._v(_vm._s((_vm$remarks = _vm.remarks) !== null && _vm$remarks !== void 0 ? _vm$remarks : "N/A"))])])])]), _c('h1', {
    staticClass: "text-center my-5"
  }, [_vm._v("Thank You For Your Business")]), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between",
    staticStyle: {
      "gap": "15px"
    }
  }, [_c('b', [_vm._v("E: " + _vm._s(_vm.officeEmail))]), _c('b', [_vm._v("M: " + _vm._s(_vm.officeMobile))])])]), _c('b', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.officeAddress))]), _c('br')], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_vm.$permissionAbility(_vm.OUTREACH_INVOICE_PAYMENT, _vm.permissions) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "disabled": _vm.status === _vm.paymentPaidConstants,
      "variant": "success",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.payInvoice();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.status === _vm.paymentPaidConstants ? "Paid" : "Pay") + " ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_vm._v(" Print ")])], 1), _c('b-card', [_c('b-col', {
    staticClass: "mt-xl-0 mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.paymentMethod ? _c('div', [_c('table', [_c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("Payment Method:")])]), _c('td', [_vm._v(_vm._s(_vm.paymentMethod))])])])]), _vm.attachments.length > 0 ? _c('div', [_c('div', [_c('b', [_vm._v("Attachments:")])]), _vm._l(_vm.attachments, function (attachment) {
    return _c('div', {
      key: attachment.id
    }, [_c('b-link', {
      staticClass: "d-flex align-items-center",
      attrs: {
        "href": attachment.url,
        "target": "_blank"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "EyeIcon"
      }
    }), _vm._v(" Attachment ")], 1)], 1);
  })], 2) : _vm._e(), _vm.paymentDetails ? _c('div', [_c('div', [_c('b', [_vm._v("Payment Details:")])]), _c('br'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.paymentDetails)
    }
  })]) : _vm._e()]) : _vm._e()])], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "display": "none",
      "flex-direction": "column",
      "width": "100%",
      "padding": "20px",
      "font-family": "Arial, sans-serif"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "20px",
      "font-family": "Arial, sans-serif"
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm.companyLogo ? [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c('div', [_c('h1', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Invoice")]), _c('b', [_vm._v(_vm._s(_vm.sellerName))]), _vm.sellerWebsite ? _c('div', [_vm._v(_vm._s(_vm.sellerWebsite))]) : _vm._e(), _vm.sellerEmail ? _c('div', [_vm._v(_vm._s(_vm.sellerEmail))]) : _vm._e(), _vm.sellerContact ? _c('div', [_vm._v(_vm._s(_vm.sellerContact))]) : _vm._e()]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('h6', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v("Invoice Number")]), _c('div', [_vm._v(_vm._s(_vm.invoiceId))]), _c('br'), _c('h6', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v("Invoice Date")]), _c('div', [_vm._v(_vm._s(_vm.invoiceDate))]), _c('br'), _c('h6', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v("Due Date")]), _c('div', [_vm._v(_vm._s(_vm.paymentDate))])]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('h6', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.companyName))]), _c('br'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Phone: " + _vm._s(this.officeMobile))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Email: " + _vm._s(this.officeEmail))])])]), _c('table', {
    staticStyle: {
      "width": "100%",
      "border-collapse": "collapse",
      "border": "1px solid #ddd"
    }
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project2, _item$project2$data, _item$currency2, _item$currency2$data;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "border": "1px solid #ddd",
        "padding": "8px"
      }
    }, [item.backlink_url ? _c('div', [_c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("Backlink: ")]), _c('a', {
      attrs: {
        "href": _vm.getBacklinkUrl(item.backlink_url),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(_vm.formatWebsite(_vm.getBacklinkUrl(item.backlink_url))))])]) : _vm._e(), item.targeted_url ? _c('div', [_c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("URL: ")]), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.targeted_url))])]) : _vm._e()]), _c('td', {
      staticStyle: {
        "border": "1px solid #ddd",
        "padding": "8px"
      }
    }, [_vm._v(_vm._s(((_item$project2 = item.project) === null || _item$project2 === void 0 ? void 0 : (_item$project2$data = _item$project2.data) === null || _item$project2$data === void 0 ? void 0 : _item$project2$data.title) || 'N/A'))]), _c('td', {
      staticStyle: {
        "border": "1px solid #ddd",
        "padding": "8px"
      }
    }, [_vm._v(_vm._s(item.backlinks_type_text || 'N/A'))]), _c('td', {
      staticStyle: {
        "border": "1px solid #ddd",
        "padding": "8px",
        "text-align": "right"
      }
    }, [_vm._v(_vm._s(((_item$currency2 = item.currency) === null || _item$currency2 === void 0 ? void 0 : (_item$currency2$data = _item$currency2.data) === null || _item$currency2$data === void 0 ? void 0 : _item$currency2$data.currency) || '') + " " + _vm._s(item.cost_price || '0.00'))])]);
  }), _c('tr', [_vm._m(1), _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.subTotalAmount))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Discount"), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.discountAmount))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Fee"), _vm.feeType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.fee_value) + "%)")] : _vm._e()], 2)]), _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.feeAmount))])])]), _c('tr', [_vm._m(2), _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right",
      "color": "red"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.grandTotalAmount))])])])], 2)]), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('h4', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("In Words: " + _vm._s(_vm.getCurrency()) + " " + _vm._s((_vm$numWords2 = _vm.numWords(_vm.grandTotalAmount)) === null || _vm$numWords2 === void 0 ? void 0 : _vm$numWords2.toUpperCase()))]), _c('h5', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Note/Terms")]), _c('b', [_vm._v(_vm._s((_vm$remarks2 = _vm.remarks) !== null && _vm$remarks2 !== void 0 ? _vm$remarks2 : "N/A"))])]), _c('h1', {
    staticStyle: {
      "text-align": "center",
      "margin": "30px 0"
    }
  }, [_vm._v("Thank You For Your Business")]), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "gap": "15px"
    }
  }, [_c('b', [_vm._v("E: " + _vm._s(_vm.officeEmail))]), _c('b', [_vm._v("M: " + _vm._s(_vm.officeMobile))])])]), _c('b', {
    staticStyle: {
      "text-align": "center",
      "display": "block"
    }
  }, [_vm._v(_vm._s(_vm.officeAddress))])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticStyle: {
      "background": "#f8f8f8"
    }
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px"
    }
  }, [_vm._v("Item")]), _c('th', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px"
    }
  }, [_vm._v("Project")]), _c('th', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px"
    }
  }, [_vm._v("Type")]), _c('th', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    }
  }, [_vm._v("Amount")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Subtotal")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border": "1px solid #ddd",
      "padding": "8px",
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v("Grand Total")])]);
}]

export { render, staticRenderFns }